"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import LineChart from './components/LineChart'
// const lineChartData = {
//   newActivations: {
//     success: [100, 120, 161, 134, 105, 160, 165, 111, 180, 90, 43, 77],
//     pending: [120, 82, 91, 154, 162, 140, 145, 45, 88, 23, 125, 96],
//     failed: [80, 62, 71, 104, 112, 40, 45, 22, 11, 33, 55, 77]
//   },
//   todayActivations: {
//     success: [10, 20, 61, 34, 5, 60, 65, 11, 22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44, 55, 66, 77, 88],
//     pending: [20, 82, 91, 54, 62, 40, 45, 22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44, 55, 66, 77, 88, 11],
//     failed: [8, 6, 1, 4, 12, 40, 45, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33, 44, 55, 66, 77, 88, 11, 22]
//   },
//   newActivationsByWeek: {
//     success: [20, 40, 61, 34, 15, 10, 16],
//     pending: [12, 8, 9, 15, 16, 10, 15],
//     failed: [50, 2, 7, 14, 11, 4, 5]
//   }
// }
var _default = {
  name: 'Dashboard',
  components: {
    PanelGroup: _PanelGroup.default // LineChart

  },
  data: function data() {
    return {// lineChartData: lineChartData.newActivations
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['profile'])),
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {// this.lineChartData = lineChartData[type]
    }
  }
};
exports.default = _default;