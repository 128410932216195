"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));

var _ImageCropper = _interopRequireDefault(require("@/components/ImageCropper"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ImageCropper: _ImageCropper.default
  },
  props: {
    tab: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      camera01: {},
      canvas01: {},
      camera02: {},
      canvas02: {},
      cameraDoc: {},
      canvasDoc: {},
      isCamera01Play: null,
      isCamera02Play: null,
      isCameraOpen01: false,
      isPhotoTaken01: false,
      isCameraOpen02: false,
      isPhotoTaken02: false,
      isCameraOpenDoc: false,
      isPhotoTakenDoc: false,
      localstream01: null,
      localstream02: null,
      localstreamDoc: null,
      attachImageShow: false,
      attachImageKey: 0,
      imgDataUrl: '',
      tempImage: '',
      images: [{
        src: '',
        height: 0
      }],
      count: 0,
      direction: 'forward',
      frame: 2,
      alertMessage: '',
      showAlert: false,
      icFrontImage: '',
      icBackImage: '',
      alertFrontMessage: '',
      alertBackMessage: '',
      showAlertFront: false,
      showAlertBack: false,
      attachImages: {}
    };
  },
  mounted: function mounted() {
    this.canvas01 = this.$refs.canvas01;
    this.camera01 = this.$refs.camera01;
    this.canvas02 = this.$refs.canvas02;
    this.camera02 = this.$refs.camera02;
    this.canvasDoc = this.$refs.canvasDoc;
    this.cameraDoc = this.$refs.cameraDoc;
  },
  methods: {
    // #region Images Slider
    previous: function previous() {
      this.count--;
      this.scroll('previous');
    },
    next: function next() {
      this.count++;
      this.scroll('next');
    },
    scroll: function scroll(position) {
      var el = document.getElementById('slide');
      var pos = 0;
      var id = setInterval(frame, 5);
      var num = this.images.length - this.frame;
      var width = 250;
      var resize = num * width;
      var check = position === 'reset' ? resize : width;

      function frame() {
        if (pos === check) {
          clearInterval(id);
        } else {
          pos += 5;

          if (position === 'next') {
            el.scrollLeft += 5;
          } else {
            el.scrollLeft -= 5;
          }
        }
      }
    },
    removeDoc: function removeDoc(index) {
      this.showAlert = false;
      console.log('image' + this.images.length);

      if (this.images.length > 1) {
        this.images.splice(index, 1);
      } else {
        this.images = [{
          src: '',
          height: 0
        }];
      }
    },
    // #endregion
    toggleCamera01: function toggleCamera01() {
      this.showAlertFront = false;
      this.icFrontImage = '';
      this.isPhotoTaken01 = false;
      this.isCameraOpen01 = true;
      this.createCameraElement01();
    },
    toggleCamera02: function toggleCamera02() {
      this.showAlertBack = false;
      this.icBackImage = '';
      this.isPhotoTaken02 = false;
      this.isCameraOpen02 = true;
      this.createCameraElement02();
    },
    toggleCameraDoc: function toggleCameraDoc() {
      if (this.images.length === 4) {
        this.showAlert = true;
        this.alertMessage = 'Max 4 Support Document Only.';
      } else {
        this.showAlert = false;

        if (this.isCameraOpenDoc) {
          this.isCameraOpenDoc = false;
          this.isPhotoTakenDoc = false;
          this.stopCameraStreamDoc();
        } else {
          this.isCameraOpenDoc = true;
          this.createCameraElementDoc();
        }
      }
    },
    captureFront: function captureFront() {
      var _this = this;

      if (this.isCamera01Play !== null) {
        if (this.isCamera01Play !== undefined) {
          this.isCamera01Play.then(function (_) {
            _this.isPhotoTaken01 = !_this.isPhotoTaken01;
            var videoRatio = _this.camera01.videoWidth / _this.camera01.videoHeight;
            var width = _this.camera01.offsetWidth;
            var height = _this.camera01.offsetHeight;
            var elementRatio = width / height;

            if (elementRatio > videoRatio) {
              width = height * videoRatio;
            } else {
              height = width / videoRatio;
            }

            _this.canvas01.width = width;
            _this.canvas01.height = height;

            var context = _this.canvas01.getContext('2d');

            context.drawImage(_this.camera01, 0, 0, width, height);
            _this.canvas01.style.display = 'block';
            _this.icFrontImage = _this.canvas01.toDataURL('image/jpeg');

            if (_this.icFrontImage !== '') {
              _this.stopCameraStream01();
            }
          }).catch(function (Error) {
            _this.isCamera01Play = null;
          });
        }
      } else {
        this.createCameraElement01();
      }
    },
    captureBack: function captureBack() {
      var _this2 = this;

      if (this.isCamera02Play !== null) {
        if (this.isCamera02Play !== undefined) {
          this.isCamera02Play.then(function (_) {
            _this2.isPhotoTaken02 = !_this2.isPhotoTaken02;
            var videoRatio = _this2.camera02.videoWidth / _this2.camera02.videoHeight;
            var width = _this2.camera02.offsetWidth;
            var height = _this2.camera02.offsetHeight;
            var elementRatio = width / height;

            if (elementRatio > videoRatio) {
              width = height * videoRatio;
            } else {
              height = width / videoRatio;
            }

            _this2.canvas02.width = width;
            _this2.canvas02.height = height;

            var context = _this2.canvas02.getContext('2d');

            context.drawImage(_this2.camera02, 0, 0, width, height);
            _this2.canvas02.style.display = 'block';
            _this2.icBackImage = _this2.canvas02.toDataURL('image/jpeg');

            if (_this2.icBackImage !== '') {
              _this2.stopCameraStream02();
            }
          }).catch(function (Error) {
            alert('Errors:' + Error);
          });
        }
      }
    },
    captureDoc: function captureDoc() {
      this.isCameraOpenDoc = false;
      this.isPhotoTakenDoc = !this.isPhotoTakenDoc;
      var videoRatio = this.cameraDoc.videoWidth / this.cameraDoc.videoHeight;
      var width = this.cameraDoc.offsetWidth;
      var height = this.cameraDoc.offsetHeight;
      var elementRatio = width / height;

      if (elementRatio > videoRatio) {
        width = height * videoRatio;
      } else {
        height = width / videoRatio;
      }

      this.canvasDoc.width = width;
      this.canvasDoc.height = height;
      this.canvasDoc.getContext('2d').drawImage(this.cameraDoc, 0, 0, width, height);
      this.canvasDoc.style.display = 'none';
      this.tempImage = this.canvasDoc.toDataURL('image/jpeg');

      if (this.images[0].src === '') {
        console.log('welcome');
        this.images[0].src = this.tempImage;
        this.images[0].height = height;
      } else {
        console.log('again');
        var image = [{
          src: '',
          height: 0
        }];
        image.src = this.tempImage;
        image.height = height;
        this.images.push(image);
      }

      this.stopCameraStreamDoc();
    },
    createCameraElement01: function createCameraElement01() {
      var _this3 = this;

      var constraints = window.constraints = {
        audio: false,
        video: {
          width: 1280,
          height: 720
        }
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          _this3.camera01.srcObject = stream;
          _this3.localstream01 = stream;

          if (_this3.isCamera01Play === null) {
            _this3.isCamera01Play = _this3.camera01.play();
          } else {
            if (_this3.isCamera01Play !== undefined) {
              _this3.isCamera01Play.then(function (_) {
                _this3.isCamera01Play = _this3.camera01.play();
              }).catch(function (Error) {
                _this3.stopCameraStream01();
              });
            }
          }
        }).catch(function (Error) {
          alert("May the browser didn't support or there is some errors:" + Error);
        });
      }
    },
    createCameraElement02: function createCameraElement02() {
      var _this4 = this;

      var constraints = window.constraints = {
        audio: false,
        video: {
          width: 1280,
          height: 720
        }
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          _this4.camera02.srcObject = stream;
          _this4.localstream02 = stream;
          _this4.isCamera02Play = _this4.camera02.play();
        }).catch(function (Error) {
          alert("May the browser didn't support or there is some errors:" + Error);
        });
      }
    },
    createCameraElementDoc: function createCameraElementDoc() {
      var _this5 = this;

      var constraints = window.constraints = {
        audio: false,
        video: {
          width: 1280,
          height: 720
        }
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          _this5.cameraDoc.srcObject = stream;
          _this5.localstreamDoc = stream;

          _this5.cameraDoc.play();
        }).catch(function (Error) {
          alert("May the browser didn't support or there is some errors:" + Error);
        });
      }
    },
    stopCameraStream01: function stopCameraStream01() {
      if (this.localstream01 !== undefined && this.localstream01 !== null) {
        this.showAlertFront = false;
        this.camera01.pause();
        this.camera01.src = ''; // this.localstream01.getTracks()[0].stop()

        var _iterator = (0, _createForOfIteratorHelper2.default)(this.localstream01.getTracks()),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var track = _step.value;
            track.stop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        console.log('IC Front Video off');
      }
    },
    stopCameraStream02: function stopCameraStream02() {
      if (this.localstream02 !== undefined && this.localstream02 !== null) {
        this.showAlertBack = false;
        this.camera02.pause();
        this.camera02.src = ''; // this.localstream02.getTracks()[0].stop()

        var _iterator2 = (0, _createForOfIteratorHelper2.default)(this.localstream02.getTracks()),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var track = _step2.value;
            track.stop();
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        console.log('IC Back Video off');
      }
    },
    stopCameraStreamDoc: function stopCameraStreamDoc() {
      if (this.localstreamDoc !== undefined && this.localstreamDoc !== null) {
        this.cameraDoc.pause();
        this.cameraDoc.src = '';
        this.localstreamDoc.getTracks()[0].stop();
        console.log('Doc Video off');
      }
    },
    // #region attach image
    attachSupportDoc: function attachSupportDoc() {
      if (this.images.length === 4) {
        this.showAlert = true;
        this.alertMessage = 'Max 4 Support Document Only.';
      } else {
        this.showAlert = false;
        this.attachImageShow = true;
      }
    },

    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess: function cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------');
      this.imgDataUrl = imgDataUrl;

      if (this.images[0].src === '') {
        this.images[0].src = this.imgDataUrl;
        this.images[0].height = 400;
      } else {
        var image = [{
          src: '',
          height: 0
        }];
        image.src = this.imgDataUrl;
        image.height = 400;
        this.images.push(image);
      }
    },

    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess: function cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------');
      console.log(jsonData); // console.log('field: ' + field)
    },

    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail: function cropUploadFail(status, field) {
      console.log('-------- upload fail --------');
      console.log(status); // console.log('field: ' + field)
    },
    close: function close() {
      this.attachImageShow = false;
    },
    // #endregion
    // #region tab function
    back: function back() {
      this.tab.currentName = '0';
    },
    nextPlan: function nextPlan() {
      if (this.icFrontImage === '' || this.icBackImage === '') {
        this.alertFrontMessage = 'Please snap IC Front';
        this.alertBackMessage = 'Please snap IC Back';
        this.showAlertFront = true;
        this.showAlertBack = true;
        window.scrollTo(0, 0);
      } else {
        this.attachImages = {
          icFrontImage: '',
          icBackImage: '',
          supportDocImages: []
        };
        this.attachImages.icFrontImage = this.icFrontImage;
        this.attachImages.icBackImage = this.icBackImage;

        if (this.images.length > 0) {
          this.attachImages.supportDocImages = this.images;
        }

        this.tab.currentName = '2';
        this.$emit('attachmentsaid', this.attachImages);
      }
    } // #endregion

  }
};
exports.default = _default;