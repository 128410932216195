"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billAdvertisementPreviewPdf = billAdvertisementPreviewPdf;
exports.deleteBillAdvertisementPage = deleteBillAdvertisementPage;
exports.editBillAdvertisementPage = editBillAdvertisementPage;
exports.getBillAdvertisementLivePage = getBillAdvertisementLivePage;
exports.getBillAdvertisementPage = getBillAdvertisementPage;
exports.getBillPdf = getBillPdf;
exports.getBills = getBills;
exports.publishBillAdvertisement = publishBillAdvertisement;
exports.searchBill = searchBill;

require("core-js/modules/es6.regexp.split");

var _request = _interopRequireDefault(require("@/utils/request"));

function getBills() {
  return (0, _request.default)({
    url: '/Bill/GetBillList',
    method: 'get'
  });
}

function searchBill(_ref) {
  var _ref$accountID = _ref.accountID,
      accountID = _ref$accountID === void 0 ? 0 : _ref$accountID,
      date = _ref.date,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 20 : _ref$limit,
      _ref$offSet = _ref.offSet,
      offSet = _ref$offSet === void 0 ? 0 : _ref$offSet,
      _ref$includeTotalCoun = _ref.includeTotalCount,
      includeTotalCount = _ref$includeTotalCoun === void 0 ? false : _ref$includeTotalCoun;
  var splitDate = date.split('-');
  var year = splitDate[0];
  var month = splitDate[1];
  return (0, _request.default)({
    url: '/Bill/Search',
    method: 'get',
    params: {
      accountID: accountID,
      year: year,
      month: month,
      limit: limit,
      offSet: offSet,
      includeTotalCount: includeTotalCount
    }
  });
}

function getBillPdf(accountId, year, month) {
  return (0, _request.default)({
    url: '/Bills/GetBillsPdf',
    method: 'get',
    params: {
      accountId: accountId,
      year: year,
      month: month
    },
    responseType: 'blob',
    timeout: 1000000
  });
}

function getBillAdvertisementPage(page) {
  return (0, _request.default)({
    url: "/Bills/BillAdvertisementImage/".concat(page),
    method: 'get',
    // params: { accountId, year, month },
    responseType: 'blob'
  });
}

function deleteBillAdvertisementPage(page) {
  return (0, _request.default)({
    url: "/Bills/BillAdvertisementImage/".concat(page),
    method: 'delete'
  });
}

function editBillAdvertisementPage(page, fileUpload) {
  // const json = JSON.stringify(obj)
  // const obj = {
  // }
  var formData = new FormData(); // formData.append('json', json)

  formData.append('fileUpload', fileUpload);
  return (0, _request.default)({
    url: "/Bills/BillAdvertisementImage/".concat(page),
    method: 'put',
    data: formData
  });
}

function getBillAdvertisementLivePage(page) {
  return (0, _request.default)({
    url: "/Bills/BillAdvertisementImage/Live/".concat(page),
    method: 'get',
    // params: { accountId, year, month },
    responseType: 'blob'
  });
}

function publishBillAdvertisement() {
  return (0, _request.default)({
    url: "Bills/BillAdvertisementImage/Publish",
    method: 'post'
  });
}

function billAdvertisementPreviewPdf(_ref2) {
  var _ref2$accountId = _ref2.accountId,
      accountId = _ref2$accountId === void 0 ? 0 : _ref2$accountId,
      _ref2$year = _ref2.year,
      year = _ref2$year === void 0 ? 0 : _ref2$year,
      _ref2$month = _ref2.month,
      month = _ref2$month === void 0 ? 0 : _ref2$month;
  return (0, _request.default)({
    url: "Bills/BillAdvertisementImage/PreviewPdf",
    method: 'get',
    params: {
      accountId: accountId,
      year: year,
      month: month
    },
    responseType: 'blob'
  });
}