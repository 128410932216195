"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

// create an axios instance
// alert(process.env.VUE_APP_BASE_URL)
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // do something before request is sent
  var token = (0, _auth.getToken)();

  if (token != null && token !== '') {
    config.headers['Authorization'] = "bearer ".concat(token);
  } // if (store.getters.token) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation
  //   // config.headers['X-Token'] = getToken()
  //   config.headers['Authorization'] = `bearer ${getToken()}`
  // }


  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  // Do something with response error
  // 401 aunauthorized, signout the user
  if (error.response && error.response.status) {
    if (error.response.status === 401) {
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
      _store.default.dispatch('user/logout').then(function () {
        location.reload();
      }); // })


      return Promise.reject(new Error(error.message || 'Error'));
    } else if (error.response.status === 403) {
      return Promise.reject('Unauthorized access');
    }
  } // console.log('err' + error) // for debug


  if (error.response) {
    if (error.response.data.constructor.name === 'Blob' && error.response.status === 404) {
      return Promise.reject(error.response.data);
    } else {
      (0, _elementUi.Message)({
        message: error.response.data.message || error.response.statusText,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(error.response.data);
    }
  } else {
    (0, _elementUi.Message)({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }

  return Promise.reject(error);
}); // // response interceptor
// service.interceptors.response.use(
//   /**
//    * If you want to get http information such as headers or status
//    * Please return  response => response
//   */
//   /**
//    * Determine the request status by custom code
//    * Here is just an example
//    * You can also judge the status by HTTP Status Code
//    */
//   response => {
//     const res = response.data
//     // if the custom code is not 20000, it is judged as an error.
//     if (res.code !== 20000) {
//       Message({
//         message: res.message || 'Error',
//         type: 'error',
//         duration: 5 * 1000
//       })
//       // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
//       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
//         // to re-login
//         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//           confirmButtonText: 'Re-Login',
//           cancelButtonText: 'Cancel',
//           type: 'warning'
//         }).then(() => {
//           store.dispatch('user/resetToken').then(() => {
//             location.reload()
//           })
//         })
//       }
//       return Promise.reject(new Error(res.message || 'Error'))
//     } else {
//       return res
//     }
//   },
//   error => {
//     console.log('err' + error) // for debug
//     Message({
//       message: error.message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )

var _default = service;
exports.default = _default;