"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPackage = addPackage;
exports.deletePackage = deletePackage;
exports.editPackage = editPackage;
exports.getPackageByCode = getPackageByCode;
exports.getPackages = getPackages;
exports.packageSearch = packageSearch;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPackages() {
  return (0, _request.default)({
    url: '/packages',
    method: 'get'
  });
}

function addPackage(data) {
  return (0, _request.default)({
    url: '/packages',
    method: 'post',
    data: data
  });
}

function getPackageByCode(code) {
  return (0, _request.default)({
    url: "/Packages/".concat(code),
    method: 'get',
    params: {
      code: code
    }
  });
}

function editPackage(code, name, mvnePackageId, creditLimit, deposit, contractMonth, predefinedVas, availableVas, advancePayment) {
  return (0, _request.default)({
    url: "/Packages/".concat(code),
    method: 'put',
    data: {
      name: name,
      mvnePackageId: mvnePackageId,
      creditLimit: creditLimit,
      deposit: deposit,
      contractMonth: contractMonth,
      predefinedVas: predefinedVas,
      availableVas: availableVas,
      advancePayment: advancePayment
    }
  });
}

function deletePackage(code) {
  return (0, _request.default)({
    url: "/Packages/".concat(code),
    method: 'delete'
  });
}

function packageSearch(_ref) {
  var _ref$offSet = _ref.offSet,
      offSet = _ref$offSet === void 0 ? 0 : _ref$offSet,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 20 : _ref$limit,
      _ref$q = _ref.q,
      q = _ref$q === void 0 ? '' : _ref$q,
      _ref$includeTotalCoun = _ref.includeTotalCount,
      includeTotalCount = _ref$includeTotalCoun === void 0 ? false : _ref$includeTotalCoun;
  return (0, _request.default)({
    url: '/Packages/search',
    method: 'get',
    params: {
      offSet: offSet,
      limit: limit,
      q: q,
      includeTotalCount: includeTotalCount
    }
  });
}