"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGroupListName = addGroupListName;
exports.deleteGroup = deleteGroup;
exports.editGroupListName = editGroupListName;
exports.getGroupAccessList = getGroupAccessList;
exports.getGroupListNameById = getGroupListNameById;
exports.getGroups = getGroups;

var _request = _interopRequireDefault(require("@/utils/request"));

function getGroups() {
  return (0, _request.default)({
    url: '/groups',
    method: 'get'
  });
}

function getGroupAccessList() {
  return (0, _request.default)({
    url: '/groups/accesslist',
    method: 'get'
  });
}

function addGroupListName(data) {
  return (0, _request.default)({
    url: '/groups',
    method: 'post',
    data: data
  });
}

function getGroupListNameById(id) {
  return (0, _request.default)({
    url: "/groups/".concat(id),
    method: 'get',
    params: {
      id: id
    }
  });
}

function editGroupListName(id, accesses) {
  return (0, _request.default)({
    url: "/groups/".concat(id),
    method: 'put',
    data: {
      accesses: accesses
    }
  });
}

function deleteGroup(id) {
  return (0, _request.default)({
    url: "/groups/".concat(id),
    method: 'delete'
  });
}