"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _path = _interopRequireDefault(require("path"));

var _validate = require("@/utils/validate");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

var _vuex = require("vuex");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/thai-cp-web/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    this.alertCount = '';
    return {
      timer: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['count'])),
  mounted: function mounted() {
    var _this = this;

    if (this.onlyOneChild !== null) {
      if (this.onlyOneChild.meta !== null) {
        if (this.onlyOneChild.meta.title === 'Alerts') {
          _store.default.dispatch('alertNotification/getAlertPendingCount');

          this.hasOneShowingChild();
        }
      }
    }

    this.timer = setInterval(function () {
      if (_this.onlyOneChild !== null) {
        if (_this.onlyOneChild.meta !== null) {
          if (_this.onlyOneChild.meta.title === 'Alerts') {
            _store.default.dispatch('alertNotification/getAlertPendingCount');

            _this.hasOneShowingChild(); //* * For Testing *//
            // var date = new Date(Date.now())
            // console.log(date + ' count' + this.count)

          }
        }
      }
    }, 60 * 1000);
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this2 = this;

      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      this.alertCount = String(this.count);
      var showingChildren = children.filter(function (item) {
        // console.log('sub menu item ' + JSON.stringify(item.meta))
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this2.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
exports.default = _default;