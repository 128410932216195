"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActivationMonthlyCount = getActivationMonthlyCount;
exports.getActivationTodayCount = getActivationTodayCount;
exports.getActivationWeeklyCount = getActivationWeeklyCount;
exports.getAlertPendingCount = getAlertPendingCount;

var _request = _interopRequireDefault(require("@/utils/request"));

function getActivationTodayCount() {
  return (0, _request.default)({
    url: '/Registrations/TodayCount',
    method: 'get'
  });
}

function getActivationWeeklyCount() {
  return (0, _request.default)({
    url: '/Registrations/WeeklyCount',
    method: 'get'
  });
}

function getActivationMonthlyCount() {
  return (0, _request.default)({
    url: '/Registrations/MonthlyCount',
    method: 'get'
  });
}

function getAlertPendingCount() {
  return (0, _request.default)({
    url: '/Dashboard/alerts/GetPendingCount',
    method: 'get'
  });
}