"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _userdetail = _interopRequireDefault(require("./components/userdetail"));

//
//
//
//
var _default = {
  components: {
    userDetail: _userdetail.default
  }
};
exports.default = _default;