"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createProject = createProject;
exports.deleteProject = deleteProject;
exports.getProjectInfo = getProjectInfo;
exports.getProjects = getProjects;
exports.searchProject = searchProject;
exports.updateProject = updateProject;

var _request = _interopRequireDefault(require("@/utils/request"));

function getProjects() {
  return (0, _request.default)({
    url: '/projects',
    method: 'get'
  });
}

function searchProject(_ref) {
  var _ref$q = _ref.q,
      q = _ref$q === void 0 ? '' : _ref$q,
      _ref$includeTotalCoun = _ref.includeTotalCount,
      includeTotalCount = _ref$includeTotalCoun === void 0 ? false : _ref$includeTotalCoun,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 20 : _ref$limit,
      _ref$offSet = _ref.offSet,
      offSet = _ref$offSet === void 0 ? 0 : _ref$offSet;
  return (0, _request.default)({
    url: '/projects/search',
    method: 'get',
    params: {
      q: q,
      includeTotalCount: includeTotalCount,
      limit: limit,
      offSet: offSet
    }
  });
}

function getProjectInfo(code) {
  return (0, _request.default)({
    url: "/projects/".concat(code),
    method: 'get'
  });
}

function createProject(data) {
  return (0, _request.default)({
    url: '/projects',
    method: 'post',
    data: data
  });
}

function updateProject(code, name, startDate, endDate, packageConfigs, allowedUserGroups) {
  return (0, _request.default)({
    url: "/projects/".concat(code),
    method: 'put',
    data: {
      name: name,
      startDate: startDate,
      endDate: endDate,
      packageConfigs: packageConfigs,
      allowedUserGroups: allowedUserGroups
    }
  });
}

function deleteProject(code) {
  return (0, _request.default)({
    url: "/projects/".concat(code),
    method: 'delete'
  });
}