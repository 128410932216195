"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _customerDetail = _interopRequireDefault(require("./components/customerDetail"));

var _attachmentDetail = _interopRequireDefault(require("./components/attachmentDetail"));

var _planAndMobileDetail = _interopRequireDefault(require("./components/planAndMobileDetail"));

var _confirmationDetail = _interopRequireDefault(require("./components/confirmationDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customerDetail: _customerDetail.default,
    attachmentDetail: _attachmentDetail.default,
    planAndMobileDetail: _planAndMobileDetail.default,
    confirmationDetail: _confirmationDetail.default
  },
  data: function data() {
    return {
      tab: {},
      custForm: {},
      subCustForm: {},
      attachImages: {},
      planForm: {},
      grandTotal: {}
    };
  },
  mounted: function mounted() {
    this.tab = this.$refs.tabMain;
  },
  methods: {
    // New Activation
    next: function next(message) {
      this.custForm = message;
      window.scrollTo(0, 0);
    },
    // Subline
    subNext: function subNext(message) {
      this.subCustForm = message;
      window.scrollTo(0, 0);
    },
    nextPlan: function nextPlan(message) {
      this.attachImages = message;
      window.scrollTo(0, 0);
    },
    nextConfirm: function nextConfirm(message) {
      this.planForm = message;
      window.scrollTo(0, 0);
    },
    nextTotal: function nextTotal(message) {
      this.grandTotal = message;
    }
  }
};
exports.default = _default;