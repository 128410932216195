"use strict";

var _interopRequireWildcard = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var oidc = _interopRequireWildcard(require("oidc-client"));

//
//
//
//
new oidc.UserManager().signinSilentCallback();
var _default = {
  data: function data() {
    return {};
  }
};
exports.default = _default;