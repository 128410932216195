"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTnc = addTnc;
exports.editPdfFile = editPdfFile;
exports.editTnc = editTnc;
exports.getTnc = getTnc;
exports.getTncActivation = getTncActivation;
exports.getTncInfo = getTncInfo;
exports.getTncPdf = getTncPdf;
exports.toggleTnc = toggleTnc;

var _request = _interopRequireDefault(require("@/utils/request"));

function getTnc() {
  return (0, _request.default)({
    url: '/fms/TermsAndConditionsForms',
    method: 'get'
  });
}

function addTnc(fileUpload, startDate, endDate) {
  var obj = {
    startDate: startDate,
    endDate: endDate
  };
  var data = JSON.stringify(obj);
  var formData = new FormData();
  formData.append('fileUpload', fileUpload);
  formData.append('data', data);
  return (0, _request.default)({
    url: '/fms/TermsAndConditionsForms',
    method: 'post',
    data: formData
  });
}

function getTncPdf(id) {
  return (0, _request.default)({
    url: "/fms/TermsAndConditionsForms/".concat(id, "/GetFormsPdf"),
    method: 'get',
    responseType: 'blob'
  });
}

function getTncInfo(id) {
  return (0, _request.default)({
    url: "/fms/TermsAndConditionsForms/".concat(id),
    method: 'get'
  });
}

function toggleTnc(_ref) {
  var id = _ref.id,
      status = _ref.status;
  return (0, _request.default)({
    url: "/fms/TermsAndConditionsForms/".concat(id, "/UpdateStatus"),
    method: 'put',
    params: {
      status: status
    }
  });
}

function editTnc(id, startDate, endDate) {
  return (0, _request.default)({
    url: "/fms/TermsAndConditionsForms/".concat(id, "/UpdateScheduledTime"),
    method: 'put',
    data: {
      startDate: startDate,
      endDate: endDate
    }
  });
}

function editPdfFile(id, replacedFile) {
  // const json = JSON.stringify(obj)
  // const obj = {
  // }
  var formData = new FormData(); // formData.append('json', json)

  formData.append('replacedFile', replacedFile);
  return (0, _request.default)({
    url: "/fms/TermsAndConditionsForms/".concat(id, "/UpdatePdfFile"),
    method: 'put',
    data: formData
  });
}

function getTncActivation() {
  return (0, _request.default)({
    url: '/TermsAndCondition',
    method: 'get',
    responseType: 'blob'
  });
}