"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.acknowledgeAlert = acknowledgeAlert;
exports.getAlertType = getAlertType;
exports.searchAlert = searchAlert;

var _request = _interopRequireDefault(require("@/utils/request"));

function getAlertType(urgency, includeTotalCount) {
  return (0, _request.default)({
    url: "/Alerts/Types",
    method: 'get',
    params: {
      urgency: urgency,
      includeTotalCount: includeTotalCount
    }
  });
}

function acknowledgeAlert(id, remarks) {
  return (0, _request.default)({
    url: "/Alerts/".concat(id, "/AcknowledgeAlert"),
    method: 'post',
    data: {
      remarks: remarks
    }
  });
}

function searchAlert(_ref) {
  var _ref$startDate = _ref.startDate,
      startDate = _ref$startDate === void 0 ? '' : _ref$startDate,
      _ref$endDate = _ref.endDate,
      endDate = _ref$endDate === void 0 ? '' : _ref$endDate,
      _ref$code = _ref.code,
      code = _ref$code === void 0 ? '' : _ref$code,
      _ref$acknowledged = _ref.acknowledged,
      acknowledged = _ref$acknowledged === void 0 ? false : _ref$acknowledged,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 20 : _ref$limit,
      _ref$offSet = _ref.offSet,
      offSet = _ref$offSet === void 0 ? 0 : _ref$offSet,
      _ref$includeTotalCoun = _ref.includeTotalCount,
      includeTotalCount = _ref$includeTotalCoun === void 0 ? false : _ref$includeTotalCoun;
  return (0, _request.default)({
    url: '/Alerts/Search',
    method: 'get',
    params: {
      startDate: startDate,
      endDate: endDate,
      code: code,
      acknowledged: acknowledged,
      limit: limit,
      offSet: offSet,
      includeTotalCount: includeTotalCount
    }
  });
}