"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCaf = addCaf;
exports.editCaf = editCaf;
exports.getCaf = getCaf;
exports.getCafInfo = getCafInfo;
exports.getPdf = getPdf;
exports.toggleCaf = toggleCaf;

var _request = _interopRequireDefault(require("@/utils/request"));

function getCaf() {
  return (0, _request.default)({
    url: '/fms/CafForms',
    method: 'get'
  });
}

function getPdf(id) {
  return (0, _request.default)({
    url: "/fms/CafForms/".concat(id, "/GetFormsPdf"),
    method: 'get',
    responseType: 'blob'
  });
}

function addCaf(fileUpload, projectType, startDate, endDate) {
  var obj = {
    projectType: projectType,
    startDate: startDate,
    endDate: endDate
  };
  var data = JSON.stringify(obj);
  var formData = new FormData();
  formData.append('fileUpload', fileUpload);
  formData.append('data', data);
  return (0, _request.default)({
    url: '/fms/CafForms',
    method: 'post',
    data: formData
  });
}

function toggleCaf(_ref) {
  var id = _ref.id,
      status = _ref.status;
  return (0, _request.default)({
    url: "/fms/CafForms/".concat(id, "/UpdateStatus"),
    method: 'put',
    params: {
      status: status
    }
  });
}

function editCaf(id, startDate, endDate) {
  return (0, _request.default)({
    url: "/fms/CafForms/".concat(id, "/UpdateScheduledTime"),
    method: 'put',
    data: {
      startDate: startDate,
      endDate: endDate
    }
  });
}

function getCafInfo(id) {
  return (0, _request.default)({
    url: "/fms/CafForms/".concat(id),
    method: 'get'
  });
}