"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatusList = getStatusList;
exports.searchSimReplacementList = searchSimReplacementList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getStatusList() {
  return (0, _request.default)({
    url: '/SimReplacements/Status',
    method: 'get'
  });
}

function searchSimReplacementList(_ref) {
  var _ref$MobileNo = _ref.MobileNo,
      MobileNo = _ref$MobileNo === void 0 ? '' : _ref$MobileNo,
      _ref$AgentId = _ref.AgentId,
      AgentId = _ref$AgentId === void 0 ? 0 : _ref$AgentId,
      _ref$Status = _ref.Status,
      Status = _ref$Status === void 0 ? 0 : _ref$Status,
      _ref$StartDate = _ref.StartDate,
      StartDate = _ref$StartDate === void 0 ? '' : _ref$StartDate,
      _ref$EndDate = _ref.EndDate,
      EndDate = _ref$EndDate === void 0 ? '' : _ref$EndDate,
      _ref$Limit = _ref.Limit,
      Limit = _ref$Limit === void 0 ? 20 : _ref$Limit,
      _ref$OffSet = _ref.OffSet,
      OffSet = _ref$OffSet === void 0 ? 0 : _ref$OffSet,
      _ref$IncludeTotalCoun = _ref.IncludeTotalCount,
      IncludeTotalCount = _ref$IncludeTotalCoun === void 0 ? false : _ref$IncludeTotalCoun;
  return (0, _request.default)({
    url: '/SimReplacements/Search',
    method: 'get',
    params: {
      MobileNo: MobileNo,
      AgentId: AgentId,
      Status: Status,
      StartDate: StartDate,
      EndDate: EndDate,
      Limit: Limit,
      OffSet: OffSet,
      IncludeTotalCount: IncludeTotalCount
    }
  });
}