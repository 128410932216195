"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/031de2d2/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _planSetting = _interopRequireDefault(require("./modules/planSetting"));

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/login/callback',
  name: 'logincallback',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/callback'));
    });
  },
  hidden: true
}, {
  path: '/login/silentcallback',
  name: 'loginsilentcallback',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/silentCallBack'));
    });
  },
  hidden: true
}, {
  path: '/403',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/403'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles accesses
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/Alerts',
  component: _layout.default,
  children: [{
    path: 'list',
    name: 'alert.list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/alert/list'));
      });
    },
    meta: {
      title: 'Alerts',
      icon: 'bell',
      activeMenu: '/Alerts/list',
      roles: ['Alert_Manage']
    }
  }]
}, {
  path: '/user',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'User',
  meta: {
    title: 'User',
    icon: 'user',
    roles: ['User_View', 'UserGroup_View']
  },
  children: [{
    path: 'users',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/index'));
      });
    },
    meta: {
      title: 'User List',
      icon: 'table',
      roles: ['User_View']
    },
    children: [{
      path: '',
      name: 'user.list',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/list'));
        });
      },
      meta: {
        activeMenu: '/user/users',
        roles: ['User_View']
      }
    }, {
      path: 'edit/:id(\\d+)',
      name: 'user.edit',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/edit'));
        });
      },
      meta: {
        title: 'Edit User',
        icon: 'table',
        activeMenu: '/user/users'
      }
    }, {
      path: 'new',
      name: 'user.new',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/new'));
        });
      },
      hidden: true,
      meta: {
        title: 'Create User',
        icon: 'edit',
        activeMenu: '/user/users'
      }
    }]
  }, {
    path: 'groups',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/group/index'));
      });
    },
    meta: {
      title: 'Groups',
      icon: 'group',
      roles: ['UserGroup_View']
    },
    children: [{
      path: '',
      name: 'ListGroupAccess',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/group/listGroup'));
        });
      },
      meta: {
        activeMenu: '/user/groups',
        roles: ['UserGroup_View']
      }
    }, {
      path: 'addGroup',
      name: 'AddGroupAccess',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/group/addGroup'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Group',
        icon: 'registration_add',
        activeMenu: '/user/groups'
      }
    }, {
      path: 'editGroup/:id',
      name: 'EditGroupAccess',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/group/editGroup'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Group Access',
        icon: 'registration_add',
        activeMenu: '/user/groups'
      }
    }]
  }]
}, {
  path: '/Activation',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'Activation',
  meta: {
    title: 'Activation',
    icon: 'activation',
    roles: ['Activation_Create', 'Activation_View']
  },
  children: [{
    path: 'New',
    name: 'ActivationNew',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activation/new'));
      });
    },
    meta: {
      title: 'New Activation',
      icon: 'registration_add',
      activeMenu: '/Activation/New',
      roles: ['Activation_Create']
    }
  }, {
    path: 'Authenticate',
    name: 'AuthenticateFace',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activation/components/authenticateFace'));
      });
    },
    hidden: true,
    meta: {
      title: 'Authenticate Face',
      icon: '',
      activeMenu: '/Activation/New'
    }
  }, {
    path: 'Activations',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activation/index'));
      });
    },
    meta: {
      title: 'Activation List',
      icon: 'list',
      roles: ['Activation_View']
    },
    children: [{
      path: '',
      name: 'ActivationList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activation/list'));
        });
      },
      meta: {
        activeMenu: '/Activation/Activations',
        roles: ['Activation_View']
      }
    }, {
      path: 'Details/:id',
      name: 'ActivationDetails',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activation/details'));
        });
      },
      hidden: true,
      meta: {
        title: 'Activation Details',
        icon: '',
        activeMenu: '/Activation/Activations'
      }
    }, {
      path: 'AddSuppDoc/:id',
      name: 'AddSupportingDocument',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/activation/addSupportDoc'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Supporting Documents',
        icon: '',
        activeMenu: '/Activation/Activations'
      }
    }]
  }]
},
/** when your routing map is too long, you can split it into small modules **/
_planSetting.default, {
  path: '/BillOnDemand',
  component: _layout.default,
  children: [{
    path: 'list',
    name: 'bill.list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/bill/list'));
      });
    },
    meta: {
      title: 'Bill on Demand',
      icon: 'documentation',
      activeMenu: '/BillOnDemand/list',
      roles: ['BillOnDemand_View']
    }
  }]
}, {
  path: '/BillAdvertisement',
  component: _layout.default,
  children: [{
    path: 'insert',
    name: 'billAdvertisement.insert',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/billAdvertisement/insertBillAd'));
      });
    },
    meta: {
      title: 'Bill Advertisement',
      icon: 'insertImage',
      activeMenu: '/BillAdvertisement/insert'
    }
  }]
}, {
  path: '/replacements',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'Replacements',
  meta: {
    title: 'Replacements',
    icon: 'replace',
    roles: ['SimCardReplacement_View', 'CallerIdReplacement_View']
  },
  children: [{
    path: 'SimReplacements',
    name: 'sim.list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/simReplacements/list'));
      });
    },
    meta: {
      title: 'Sim Card',
      icon: 'sim_card',
      activeMenu: '/replacements/SimReplacements',
      roles: ['SimCardReplacement_View']
    }
  }, {
    path: 'CalleridReplacements',
    name: 'callerid.list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/calleridReplacements/list'));
      });
    },
    meta: {
      title: 'Caller ID',
      icon: 'mobile',
      activeMenu: '/replacements/CalleridReplacements',
      roles: ['CallerIdReplacement_View']
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;